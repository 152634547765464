<template>
  <div class="my-booking-detail">
    <v-btn
      cols="12"
      color="grey"
      text
      dark
      small
      style="position:relative; top:10px; left:10px;"
      @click="$router.push('/meeting-activity/my-booking')"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <div v-if="getIsLoading === false">
      <div v-if="permission.read_perm === 1">
        <v-card
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:95%; margin-top:20px;background-color: rgba(255,255,255, 0.95);"
        >
          <v-form ref="headerForm" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-toolbar flat style="border-radius:10px 10px 0 0;">
                  <v-btn
                    type="button"
                    class="mx-2 elevation-4 booking-detail-title-icon"
                    dark
                    large
                    color="cyan"
                    style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                  >
                    <v-icon large dark>
                      mdi-notebook-multiple
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title class="booking-detail-title"
                    >BOOKING DETAIL</v-toolbar-title
                  >
                  <v-spacer />
                  <v-btn
                    v-if="permission.update_perm === 1 && doc_status.id !== -1"
                    @click="cancelForm"
                    dense
                    tile
                    small
                    elevation="0"
                    type="button"
                    color="grey"
                    class="ma-2 white--text"
                    style="width:80px;"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Cancel
                  </v-btn>
                  <v-toolbar-title
                    class=""
                    :style="
                      `border-left:1px solid #e0e0e0; border-right:1px solid #e0e0e0; padding:20px 40px; font-weight:bold; color:${statusColor};`
                    "
                  >
                    {{ doc_status.name }}
                  </v-toolbar-title>
                </v-toolbar>
              </v-col>
            </v-row>
            <v-divider />

            <v-container>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="12" sm="1" md="3">
                  <v-text-field
                    v-model="form.employee_name"
                    label="PIC"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="3">
                  <v-text-field v-model="form.email" label="Email" readonly />
                </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-text-field
                    v-model="form.department_name"
                    label="Department"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-text-field
                    v-model="form.booking_no"
                    label="Book No"
                    readonly
                    style="font-size:12px;"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-autocomplete
                    v-model="form.room"
                    :items="getRoomDropdown"
                    :loading="loadingAutocomplete"
                    item-text="name"
                    item-value="id"
                    cache-items
                    class="mx-1"
                    hide-no-data
                    hide-details
                    label="Ruang Meeting (Diajukan)"
                    return-id
                    style="margin-bottom:30px;"
                    :readonly="!isEdit"
                    :clearable="isEdit"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="2"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-autocomplete
                    disabled
                    v-model="form.room"
                    :items="getRoomDropdown"
                    :loading="loadingAutocomplete"
                    item-text="name"
                    item-value="id"
                    cache-items
                    class="mx-1"
                    hide-no-data
                    hide-details
                    label="Ruang Meeting (Perubahan)"
                    return-id
                    :readonly="!isEdit"
                    :clearable="isEdit"
                    style="margin-bottom:30px;"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-text-field
                    v-model="form.start_use"
                    label="Mulai Penggunaan (YYYY/MM/DD hh:mm:ss)"
                    :readonly="!isEdit"
                    :clearable="isEdit"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="2"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-text-field
                    v-model="form.end_use"
                    label="Selesai Penggunaan (YYYY/MM/DD hh:mm:ss)"
                    :readonly="!isEdit"
                    :clearable="isEdit"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="12" sm="1" md="4">
                  <v-autocomplete
                    v-model="form.meeting_type"
                    :items="[
                      { id: 1, name: 'Internal' },
                      { id: 2, name: 'Eksternal' },
                      { id: 3, name: 'Vendor' },
                      { id: 4, name: 'Direksi' },
                      { id: 5, name: 'Lainnya' }
                    ]"
                    :loading="loadingAutocomplete"
                    item-text="name"
                    item-value="id"
                    cache-items
                    class="mx-1"
                    hide-no-data
                    hide-details
                    label="Jenis Meeting"
                    return-id
                    style="margin-bottom:30px;"
                    :readonly="!isEdit"
                    :clearable="isEdit"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="2"> </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-text-field
                    type="number"
                    v-model="form.number_of_attendance"
                    label="Jumlah Peserta"
                    :readonly="!isEdit"
                    :clearable="isEdit"
                  />
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="10" style="padding:0 15px;">
                  <v-textarea
                    v-model="form.description"
                    outlined
                    dense
                    clear-icon="mdi-close-circle"
                    label="Keterangan"
                    style="margin-top:5px; "
                    :readonly="!isEdit"
                    :clearable="isEdit"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="10" style="padding:0 15px;">
                  <v-textarea
                    v-model="note_from_hr"
                    outlined
                    dense
                    value="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                    readonly
                    clear-icon="mdi-close-circle"
                    label="Keterangan HRD"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="1" md="1"> </v-col>
                <v-col cols="10" style="padding:0 15px;">
                  <v-textarea
                    v-model="note_from_mis"
                    outlined
                    dense
                    readonly
                    clear-icon="mdi-close-circle"
                    label="Keterangan MIS"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="1" md="1"> </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <v-card
          flat
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:95%; margin-top:1px; margin-bottom:10px;"
        >
          <v-data-table
            :headers="headers"
            :items="matrix"
            class="elevation-1 mx-auto"
            style="width:99%; margin-bottom:10px; border:1px solid #e0e0e0"
            :items-per-page="itemsPerPage"
            :options.sync="options"
            :server-items-length="totalSequenceData"
            :loading="matrix.length === 0"
            hide-default-footer
          >
            <template v-slot:[`item.document_no`]="{ item }">
              <div style="width:140px; fontSize:12px;">
                {{ item.document_no }}
              </div>
            </template>
            <template v-slot:[`item.table_name`]="{ item }">
              <div style="width:140px; fontSize:12px;">
                {{ item.table_name }}
              </div>
            </template>
            <template v-slot:[`item.approver_user`]="{ item }">
              <div @click="rowClick(item)" style="cursor:pointer;">
                <v-chip
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                  v-for="(item, index) in item.approver_user"
                  :key="index"
                >
                  {{ item.user.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.approved`]="{ item }">
              <div v-for="(items, index) in item.approver_user" :key="index">
                <v-chip
                  v-if="items.status === 1"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ items.user.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.rejected`]="{ item }">
              <div v-for="(items, index) in item.approver_user" :key="index">
                <v-chip
                  v-if="items.status === -1"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ items.user.name }}
                </v-chip>
              </div> </template
            ><template v-slot:[`item.date`]="{ item }">
              <div style="width:140px; fontSize:12px;">
                {{ item.updated_at }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <div v-else style="height:83vh; padding-top:50px;">
        <AccessDenied />
      </div>
    </div>
    <div v-else style="height:88vh;">
      <Loader />
    </div>
  </div>
</template>
<script>
import Loader from '../../../components/common/loader/CircularProgress'
import AccessDenied from '../../../components/common/accessDenied/accessDenied'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      menu_key: 25,
      permission: {},
      application: {
        id: 9,
        name: 'Room Activity'
      },
      loading: false,
      loadingAutocomplete: false,
      form: {},
      note_from_hr: '',
      note_from_mis: '',
      valid: false,
      dateRule: [v => !!v || 'Tgl.Pengajuan is required'],

      itemsPerPage: 10,
      headers: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        { text: 'Min. Approver', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' },
        { text: 'Update', value: 'date', align: 'center' }
      ],
      totalSequenceData: 1,
      options: {},
      isEdit: false,
      statusColor: '',
      matrix: [],
      doc_status: {}
    }
  },
  components: { Loader, AccessDenied },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            this.loadPage()
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
              setTimeout(() => {
                this.setIsLoading(false)
              }, 1500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1500)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getIsLoading',
      'getBookingDetail',
      'getRoomDropdown',
      'getUserProfile'
    ])
  },
  methods: {
    ...mapActions([
      'fetchMatrix',
      'requestRoom',
      'approvalMatrixDocumentList',
      'menu_access',
      'bookingDetail',
      'cancelRoomBooking'
    ]),
    ...mapMutations(['setIsLoading', 'setPermission']),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            title:
              pModalType === 'success'
                ? '<strong>Success</strong>'
                : `<strong>${pModalType}</strong>`,
            icon: pModalType,
            html: pStatusMsg,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            timer: 5000
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    loadPage() {
      setTimeout(() => {
        this.initForm()
        this.bookingDetail().then(res => {
          console.log(res)
          this.doc_status = {
            id: res.data.status.id,
            name: res.data.status.name
          }
        })
        this.checkMatrix(this.getBookingDetail)
      }, 1000)
    },
    checkMatrix(data) {
      this.matrixCheck(data)
    },
    matrixCheck(data) {
      this.approvalMatrixDocumentList(data)
        .then(res => {
          if (res.status_code === '00') {
            this.matrix = res.data
          } else {
            this.showMsgDialog('error', res.status_msg)
          }
        })
        .catch(err => {
          console.log('AUTHORITY ERROR ->', err)
          // this.showMsgDialog(
          //   'error',
          //   err
          //     ? 'Authority check error, please contact an Admin'
          //     : 'Authority check error, please contact an Admin'
          // )
        })
    },
    initForm() {
      this.setIsLoading(false)
      this.form = {
        booking_no: this.getBookingDetail.booking_no,
        company_name: this.getBookingDetail.company_name,
        confirm_hr_by_name: this.getBookingDetail.confirm_hr_by_name,
        confirm_mis_by_name: this.getBookingDetail.confirm_mis_by_name,
        confirm_status_hr: this.getBookingDetail.confirm_status_hr,
        confirm_status_mis: this.getBookingDetail.confirm_status_mis,
        created_at: this.getBookingDetail.created_at,
        created_by_name: this.getBookingDetail.created_by_name,
        department_name: this.getBookingDetail.department_name,
        description: this.getBookingDetail.description,
        email: this.getBookingDetail.email,
        employee_name: this.getBookingDetail.employee_name,
        end_use: this.changeDateFormat(
          new Date(this.getBookingDetail.end_use).toLocaleString()
        ),
        id: this.getBookingDetail.id,
        meeting_type: this.getBookingDetail.meeting_type.id,
        meeting_type_other: this.getBookingDetail.meeting_type_other,
        number_of_attendance: this.getBookingDetail.number_of_attendance,
        room: this.getBookingDetail.room.id,
        start_use: this.changeDateFormat(
          new Date(this.getBookingDetail.start_use).toLocaleString()
        ),
        status: this.getBookingDetail.status
      }
      this.note_from_hr = this.getBookingDetail.note_from_hr
      this.note_from_mis = this.getBookingDetail.note_from_mis
      this.setColor(this.form.status.name)
    },
    editForm() {
      this.isEdit = true
    },
    cancelForm() {
      this.showMsgDialog('question', 'Cancel this request').then(res => {
        if (res.isConfirmed) {
          this.setIsLoading(true)
          this.cancelRoomBooking(this.getBookingDetail)
            .then(res => {
              console.log(res)
              if (res.status_code === '00') {
                this.showMsgDialog('success', res.status_msg)
                this.loadPage()
              } else {
                this.showMsgDialog('error', res.status_msg)
              }
              this.setIsLoading(false)
            })
            .catch(err => {
              this.setIsLoading(false)
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, please contact an Admin'
                  : 'Something went wrong, please contact an Admin'
              )
            })
        } else if (res.dismiss === 'cancel') {
        } else if (res.dismiss === 'backdrop') {
        }
      })
    },
    setColor(val) {
      switch (val) {
        case 'Approved':
          this.statusColor = 'green'
          break
        case 'Rejected':
          this.statusColor = 'red'
          break
        default:
          this.statusColor = 'rgba(0,0,0,0.5)'
          break
      }
    },

    changeDateFormat(date) {
      const day = date.slice(0, date.indexOf('/'))
      const month = date.slice(date.indexOf('/') + 1, date.lastIndexOf('/'))
      const year = date.slice(date.lastIndexOf('/') + 1, date.indexOf(','))
      const time = date.slice(date.indexOf(',') + 2)
      return year + '/' + month + '/' + day + ' ' + time
    },
    rowClick(item) {
      console.log(item)
    }
  }
}
</script>

<style lang="scss" src="">
.my-booking-detail {
  position: relative;
  z-index: 2;
}
@media (max-width: 576px) {
  .booking-detail-title-icon {
    display: none;
  }
  .booking-detail-title {
    font-size: 16px;
  }
}
</style>
